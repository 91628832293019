<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <b-img
            style="width: 100%"
            :src="getImageUrl()"
          />
        </b-link>

        <b-card-title class="mb-1 text-primary">
          Bienvenido de Nuevo! 👋
        </b-card-title>
        <b-card-text class="mb-2 text-primary">
          Inicia sesión en tu cuenta y comienza la aventura.
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >

            <!-- email -->
            <b-form-group
              label-for="email"
              label="Correo"
              class="text-primary"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="mail@example.com"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group
              class="text-primary"
            >
              <!--
              <div class="d-flex justify-content-between">
                <label for="password">Contraseña</label>
                <b-link :to="{name:'auth-forgot-password-v1'}">
                  <small>Olvidaste la contraseña?</small>
                </b-link>
              </div>
              -->
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="login-password"
                    placeholder="Contraseña"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                Recuérdame
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
            >
              Iniciar Sesión
            </b-button>
          </b-form>
        </validation-observer>
        <br>
        <br>
        <!--b-card-text class="text-danger align-items-center">
          <center>
            {{ getTextMessage() }}
          </center>
        </b-card-text-->
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox, BImg,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { logon } from '@/requests/autentication'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
// import { datadogRum } from '@datadog/browser-rum'
// import { datadogLogs } from '@datadog/browser-logs'

export default {
  components: {
    // BSV
    BImg,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      password: '',
      status: '',
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    getImageUrl() {
      const { host } = window.location

      // Lógica para determinar la imagen según la URL
      if (host.includes('apsistema')) {
        // eslint-disable-next-line global-require
        return require('@/assets/images/logo/apvnlogo.png')
      }
      // eslint-disable-next-line global-require
      return require('@/assets/images/logo/apmixlogo.png')
    },
    getTextMessage() {
      const { host } = window.location
      if (host.includes('apmixsa')) {
        return 'Sistema inactivo por falta de pago, contacte a su administrador'
      }
      return ''
    },
    login() {
      const currentDate = new Date()
      const dateValue = currentDate.getTime()
      this.$refs.loginForm.validate()
        .then(async success => {
          if (success) {
            try {
              const response = await logon(this.userEmail, this.password)
              const user = response
              if (user !== undefined || user !== null) {
                localStorage.setItem('loginFecha', `${dateValue}`)
                user.password = this.password
                const Rol = user.rol[0].access.ROLE
                user.role = Rol
                user.ability = user.rol[0].access.ability
                localStorage.setItem('userData', JSON.stringify(user))
                this.$ability.update(user.ability)
                /* datadogRum.startSessionReplayRecording()
                datadogRum.setUser({
                  id: user.id,
                  name: `${user.firstName} ${user.firstSurname}`,
                  email: user.email,
                  role: user.role,
                  company: user.company.name,
                })

                datadogLogs.logger.setContext({
                  id: user.id,
                  name: `${user.firstName} ${user.firstSurname}`,
                  email: user.email,
                }) */

                this.$router
                  .replace(getHomeRouteForLoggedInUser(Rol))
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: `Bienvenido ${`${user.firstName} ${user.firstSurname}`}`,
                        icon: 'UserIcon',
                        variant: 'success',
                        text: `Has iniciado sesión con el rol de ${Rol} . Ahora ya puedes usar el sistema!`,
                      },
                    })
                  })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Problemas de Conexión',
                    icon: 'CloudOffIcon',
                    variant: 'warning',
                    text:
                            'Se tuvo un inconveniente al iniciar sesión, intente nuevamente!',
                  },
                })
              }
            } catch (error) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Login Incorecto',
                  icon: 'MehIcon',
                  variant: 'warning',
                  text: 'Usuario y/o contraseña incorrectos!',
                },
              })
              this.password = null
              this.$refs.loginForm.setErrors(error)
              console.error('error in login', error)
            }
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
